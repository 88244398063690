import cn from 'classnames';
import React from 'react';
import { Link } from '@/Framework/Router/Next/Link';
import finsightLogo from '@/Framework/assets/logo/finsightLogo.svg';
import { useGlobalErrorContext } from '@/Framework/ErrorHandling/GlobalError/GlobalErrorContext';
import GlobalError from '@/Framework/ErrorHandling/GlobalError';
import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { Icon } from '@dealroadshow/uikit/core/components/Icon';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import styles from './usersContent.scss';

interface IProps {
  children?: React.ReactNode,
  title?: React.ReactNode,
  subtitle?: string | React.ReactNode,
  className?: string,
  titleClassName?: string,
  subTitleClassName?: string,
  contentClassName?: string,
  contentWrpClassName?: string,
  innerContentClassName?: string,
  showPreloader?: boolean,
  footerContent?: React.ReactNode,
  logo?: string,
  logoTitle?: string | null,
  logoLink?: string,
  isWide?: boolean,
  isExtraWide?: boolean,
  icon?: typeof finsightLogo,
  iconClassName?: string,
  copyright?: string | React.ReactNode,
  wrapperClassName?: string,
  copyrightClassName?: string,
  withLogo?: boolean,
}

const UsersContent = ({
  children,
  title,
  subtitle,
  className,
  titleClassName,
  subTitleClassName,
  contentClassName,
  contentWrpClassName,
  innerContentClassName,
  showPreloader,
  footerContent,
  logo = finsightLogo,
  logoTitle,
  logoLink,
  isWide = false,
  isExtraWide = false,
  icon,
  iconClassName,
  wrapperClassName,
  copyrightClassName,
  withLogo = true,
  copyright = '© FINSIGHT Group Inc.',
}: IProps) => {
  const containerCls = cn(
    styles.container,
    className,
    {
      [styles.isWide]: isWide,
      [styles.isExtraWide]: isExtraWide,
    },
  );

  const logoElement = logoTitle ? <h2>{ logoTitle }</h2> : <img src={ logo } alt="Logo" />;
  const logoComponent = logoLink ? (
    <Link target="_blank" to={ logoLink } className={ styles.logoWrp }>
      { logoElement }
    </Link>
  ) : (
    <div className={ styles.logoWrp }>
      { logoElement }
    </div>
  );

  const { isGlobalErrorVisible } = useGlobalErrorContext();

  return (
    <div className={ cn(styles.wrp, wrapperClassName) }>
      <div className={ styles.aligner }>
        <div className={ containerCls }>
          { withLogo && (
            <div className={ styles.logoWrp }>
              { logoComponent }
            </div>
          ) }
          <div className={ cn('contentWrp', styles.contentWrp, contentWrpClassName) }>
            { showPreloader && <Spinner overlay /> }
            <div className={ cn(styles.content, contentClassName) }>
              { icon && (
                <div>
                  <Icon type={ icon } className={ cn(styles.icon, iconClassName) } />
                </div>
                ) }
              { title && (
                <h1 className={ cn('title', headerStyles.isH2, styles.title, titleClassName) }>{ title }</h1>
              ) }
              { subtitle && !isGlobalErrorVisible && (
                <div className={ cn('subtitle', styles.subtitle, subTitleClassName) }>
                  { subtitle }
                </div>
                ) }
              <GlobalError className={ cn(styles.subtitle, styles.errorMessage) } />
              <div className={ cn(styles.innerContent, innerContentClassName) }>
                { children }
              </div>
            </div>
            { !!footerContent && (
              <div className={ styles.footer }>
                { footerContent }
              </div>
              ) }
          </div>
          <div
            className={ cn(styles.copyright, copyrightClassName) }
            dangerouslySetInnerHTML={ { __html: copyright } }
          />
        </div>
      </div>
    </div>
  );
};

export default UsersContent;
