import React from 'react';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';
import { Input, IInputProps } from '@dealroadshow/uikit/core/components/Input';

interface IProps extends IInputProps {
  placeholder?: string,
}

function PasswordInput(props: IProps) {
  return (
    <Input
      type="password"
      placeholder={ props.placeholder || 'Password' }
      icon={ IconLock }
      { ...props }
    />
  );
}

export default PasswordInput;
